import { useRef } from 'react';
import { BASE_PATH } from '../../enum/base.enum';
import { useFlag } from '@unleash/proxy-client-react';
import { UnleashEnum } from '../../enum/unleash-enum';

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}
const PopUpModal = (props: ModalProps) => {
    const { isOpen, onClose, children } = props;
    const outsideRef = useRef(null);
    const planOptionShow = useFlag(UnleashEnum.PLAN_CHANGE_COMPONENT);
    const handleCloseOnOverlay = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
        if (e.target === outsideRef.current) {
            onClose();
        }
    };

    return (
        <>
            {isOpen ? (
                <div
                    ref={outsideRef}
                    className="fixed top-0 bottom-0 flex justify-center items-center w-[100%] left-0 right-0 h-[100vh] z-[999] bg-[rgba(127,127,127,0.5)] mx-auto"
                    onClick={handleCloseOnOverlay}
                >
                    {planOptionShow ? (
                        <div
                            className={
                                'mb:fixed mb:w-[100%] mb:bottom-0 mb:rounded-none mb:rounded-t-[16px] mb:max-w-[100%]  bg-white h-max m-auto cursor-pointer relative max-w-[374px] w-[90%] rounded-[16px]'
                            }
                        >
                            <div className="absolute right-8 top-12">
                                <img
                                    src={`${BASE_PATH}/assets/images/cross-green.svg`}
                                    className="w-12 h-12 relative"
                                    onClick={onClose}
                                />
                            </div>
                            <div className="max-h-[566px]">{children}</div>
                        </div>
                    ) : (
                        <div className="relative max-w-[374px] min-w-[360px] w-[90%]  rounded-lg bg-white h-max m-auto cursor-pointer">
                            <div className="absolute right-4 top-4">
                                <img
                                    src={`${BASE_PATH}/assets/images/close.svg`}
                                    className="w-8 h-8 relative"
                                    onClick={onClose}
                                />
                            </div>
                            <div className="p-[16px] max-h-[566px]">{children}</div>
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
};
export { PopUpModal };