import { useEffect, useRef, useState } from 'react';
import { useInterval } from '../../utils/polling.helper';
import { useUrlParamParser } from '../util/url-param-parse.hook';
import { OrderService } from '../../service/order.service';
import { PollingStatus } from '../../enum/enum';
import { UserService } from '../../service/user.service';
import { useRedirectionHelper } from '../util/use-redirection.hook';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

const OrderVerification = () => {
    const transactionParamModel = useUrlParamParser();
    const { mobile, checkoutId } = transactionParamModel;
    const { checkRedirection } = useRedirectionHelper();
    const pollingCount = useRef(0);
    const navigate = useNavigate();

    const pollingInterval = Number(process.env.REACT_APP_IVR_POLLING_INTERVAL || 5000);
    const pollingCountLimit = Number(process.env.REACT_APP_IVR_POLLING_COUNT || 20);
    const orderConfirmationNumber = process.env.REACT_APP_ORDER_CONFIRMATION_NUMBER || '07948222638';

    const { stopInterval } = useInterval(
        async () => {
            if (pollingCount.current > pollingCountLimit) {
                navigate(`/failure?checkoutId=${checkoutId}`, {
                    state: {
                        message: "We couldn't confirm your order on call after multiple attempts",
                        checkoutRedirection: true
                    },
                    replace: true
                });
                stopInterval();
            } else {
                pollingCount.current = pollingCount.current + 1;
                try {
                    const {
                        data: { status }
                    } = await OrderService.getOrderVerificationStatus(checkoutId as string);

                    if (status === PollingStatus.SUCCESS) {
                        stopInterval();
                        const res = await UserService.moveNext(checkoutId as string, {});
                        checkRedirection(res.data);
                    } else if (status === PollingStatus.FAILURE) {
                        stopInterval();
                        navigate(`/failure?checkoutId=${transactionParamModel.checkoutId}`, {
                            state: {
                                message: "We couldn't confirm your order on call after multiple attempts",
                                checkoutRedirection: true
                            },
                            replace: true
                        });
                    }
                } catch (error) {
                    stopInterval();
                    if (error instanceof AxiosError) {
                        navigate(`/failure?checkoutId=${checkoutId}`, {
                            replace: true,
                            state: { message: error.response?.data.message, checkoutRedirection: true }
                        });
                    }
                }
            }
        },
        true,
        pollingInterval
    );

    return (
        <div>
            <div className="mt-[40px] flex flex-col items-center">
                <div className="w-[300px]">
                    <p className="text-[18px] font-openSansBold text-headerGray text-center">
                        Calling you on <span className="text-lightBlue">+91-{mobile} </span>
                        to confirm order{' '}
                    </p>
                </div>
                <img src="/assets/images/ivr-verify.svg" className="w-[100px] h-[190px] mt-[25px]" />
                <div className="mt-[36px] w-[260px] text-headerGray font-robotoBold text-[14px]">
                    <p className="text-center">Call in progress : </p>
                    <p className="text-center">Press the specified number to confirm order... </p>
                </div>
                <div className="mt-[20px] w-[268px] text-lightGreyish font-robotoBold text-[12px] leading-[14px]">
                    <p className="text-center">Did not receive a call?</p>
                    <p className="text-center">
                        Call {orderConfirmationNumber}{' '}
                        <span className="font-robotoRegular leading-[16px]"> from your registered number to </span>
                    </p>
                    <p className="font-robotoRegular text-center">confirm your order</p>
                </div>
                <div className="w-fit mt-[50px]">
                    <div className="flex items-center gap-[6px]">
                        <img src="/assets/images/snapmint-logo.svg" className='w-[25px] h-[25px]' />
                        <p className="text-[14px] text-center font-robotoRegular text-headerGray">
                            Pay EMIs on Snapmint App
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrderVerification;
