const RegEx = {
  validMobileNumberRegEx: /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/,
};
export enum PersonalLoan {
  PERSONAL_LOAN = 'personal_loan'
}

export enum LogLevel{
  DEBUG='debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error'
}

export enum PollingStatus {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILURE = 'failure'
}

export { RegEx };
