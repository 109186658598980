import Axios, { AxiosResponse } from 'axios';
import { BASE_API_PATH } from '../enum/base.enum';
import { PollingStatus } from '../enum/enum';

export const OrderService = {
    cancelOrder(checkoutId: string): Promise<
        AxiosResponse<{
            status: string;
            merchant_data: { action: string; method: string; merchant_params: { [key: string]: string } };
        }>
    > {
        return Axios.post(`${BASE_API_PATH}/orders/${checkoutId}/cancel?checkoutId=${checkoutId}`);
    },
    getOrderVerificationStatus(checkoutId: string): Promise<AxiosResponse<{ status: PollingStatus }>> {
        return Axios.get(`${BASE_API_PATH}/orders/verification-status?checkoutId=${checkoutId}`);
    }
};
